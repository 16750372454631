var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data,"loading":_vm.loading,"options":{
      sortBy: ['id']
    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.year)+"-"+_vm._s(String(item.month).padStart(2, '0'))+" ")]}},{key:"item.status.color",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status.color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t(item.status.label))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.status.canShowReport,"to":{name: 'ReportShow', params: {
          id: String(item.id)
        }},"link":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_PREVIEW'))+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!item.status.canDownloadReport,"loading":item.isReportDownloading,"color":"info","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadReport(item)}}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_XLSX'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }