import ReportRepository from '@/repositories/ReportRepository';
import ReportFactory from '@/factories/ReportFactory';

export default class ReportService {
    static async getReportsList(year: string | number, month: string | number) {
        const {data} = await ReportRepository.getAll(year, month);

        return data.map((item: any) => ReportFactory.toReport(item));
    }

    static async getReport(reportId: number | string) {
        const {data} = await ReportRepository.getReport(reportId);
        return data.map((row: any) => ReportFactory.toReportRow(row));
    }
}
