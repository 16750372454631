





















import {Component, Prop, Vue} from 'vue-property-decorator';
import IReportRow from '@/types/IReportRow';
import {DataTableHeader} from 'vuetify';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

@Component({name: 'ReportInfoTable'})
export default class ReportInfoTable extends Vue {
  @Prop()
  data!: IReportRow[];

  @Prop()
  loading!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_CLASSIFICATION_EVENT_TYPE',
      value: 'type',
    },
    {
      text: 'SYSTEM_CLASSIFICATION_COUNTRY_OF_TAXATION',
      value: 'countryTaxable',
    },
    {
      text: 'SYSTEM_NETTO',
      value: 'netCountry',
      align: 'end',
    },
    {
      text: 'SYSTEM_VAT',
      value: 'taxCountry',
      align: 'end',
    },
    {
      text: 'SYSTEM_BRUTTO',
      value: 'grossCountry',
      align: 'end',
    },
    // {
    //   text: 'SYSTEM_VAT_VALUE',
    //   value: 'countryTaxableTax',
    //   align: 'center',
    // },
    {
      text: 'SYSTEM_CURRENCY',
      value: 'countryTaxableCurrency',
      align: 'center',
    },
  ];

  formatNumber(value: number) {
    return formatNumberWithSpaces(value, 2);
  }
}
