


























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import IYearAndMonthFilter from '@/types/IYearAndMonthFilter';

@Component({name: 'YearAndMonthFilter'})
export default class YearAndMonthFilter extends Vue {
  @Prop()
  filters!: IYearAndMonthFilter

  months = [
    {
      key: 1,
      value: 'JANUARY',
    },
    {
      key: 2,
      value: 'FEBRUARY',
    },
    {
      key: 3,
      value: 'MARCH',
    },
    {
      key: 4,
      value: 'APRIL',
    },
    {
      key: 5,
      value: 'MAY',
    },
    {
      key: 6,
      value: 'JUNE',
    },
    {
      key: 7,
      value: 'JULY',
    },
    {
      key: 8,
      value: 'AUGUST',
    },
    {
      key: 9,
      value: 'SEPTEMBER',
    },
    {
      key: 10,
      value: 'OCTOBER',
    },
    {
      key: 11,
      value: 'NOVEMBER',
    },
    {
      key: 12,
      value: 'DECEMBER',
    },
  ];
  years: number[] = [];

  mounted() {
    this.generateYears();
  }

  @Emit('changed')
  changed() {
    return this.filters;
  }

  translate(val: { key: string | number, value: any }) {
    return this.$t(`SYSTEM_${val.value}`);
  }

  clearFilters() {
    this.filters.year = null;
    this.filters.month = null;
  }

  generateYears() {
    const now = new Date().getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.years.push(now - i);
    }
  }
}
