





















































import {Component, Vue} from 'vue-property-decorator';
import YearAndMonthFilter from '@/components/YearAndMonthFilter.vue';
import IReportsListParams from '@/types/IReportsListParams';
import ReportTable from '@/components/Report/ReportTable.vue';
import IReport from '@/types/IReport';
import ReportService from '@/services/ReportService';
import {resolveError} from '@/utils/notifications';
import ReportRepository from '@/repositories/ReportRepository';
import {isPermission} from '@/utils/perrmissions';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Reports',
  components: {
    PageWrap,
    ReportTable,
    YearAndMonthFilter,
  },
})
export default class Reports extends Vue {

  reports: IReport[] = [];
  params: IReportsListParams = {
    month: null,
    year: null,
    page: 1,
    size: 10,
    totalElements: 0,
  };
  loading: boolean = false;
  generateReportLoading = false;

  get reportDate(): string {
    if (this.isDateSelected) {
      return `${this.params.year}-${String(this.params.month).padStart(2, '0')}`;
    }
    return '';
  }

  get isDateSelected(): boolean {
    return !!(this.params.year && this.params.month);
  }

  created() {
    this.getSavedFilters()
    this.isDateSelected && this.fetchData()
  }

  getSavedFilters() {
    const { filters } = getSavedPageData()
    if (filters) {
      this.params.year = filters.year || null;
      this.params.month = filters.month || null;
    }
  }

  async generateReport() {
    try {
      this.generateReportLoading = true;
      await ReportRepository.generateReport(this.params.year!, this.params.month!);
      this.fetchData();
    } catch (e) {
      resolveError(e, 'generate');
    } finally {
      this.generateReportLoading = false;
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      this.reports = await ReportService.getReportsList(this.params.year!, this.params.month!);
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  onFiltersChanged(filters: YearAndMonthFilter) {
    this.params = {...this.params, ...filters};
    savePageData({
      filters
    })
    if (this.isDateSelected) {
      this.fetchData();
    } else {
      this.reports = [];
    }
  }

  isPermission(permissions: string[]): boolean {
    return isPermission(permissions);
  }

}
